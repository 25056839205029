@import "../../../styles/breakpoints.scss";

.container {
  text-align: center;

  .button {
    margin-bottom: var(--spacing-large);
  }

  .title {
    margin-bottom: var(--spacing-huge);
    h1 {
      color: var(--gray-dark);
    }
  }

  .dates {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--spacing-huge);

    @include tablet-portrait {
      flex-direction: row;
      justify-content: center;
    }

    .date {
      margin: var(--spacing-base) 0;

      @include tablet-portrait {
        margin: 0 var(--spacing-huge);
      }

      span {
        color: var(--gray-dark);
      }
    }
  }

  .statistics {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--spacing-huge);

    @include tablet-portrait {
      flex-direction: row;
      justify-content: center;
    }

    .statistic {
      margin: var(--spacing-base) 0;
      min-width: 130px;

      @include tablet-portrait {
        margin: 0 var(--spacing-huge);
      }

      &__status {
        display: flex;
        justify-content: space-between;

        margin: var(--spacing-base) 0;

        .value {
          color: var(--gray-dark);
          font-size: 16px;
        }
      }
    }
  }

  .select {
    margin-bottom: var(--spacing-huge);
    display: flex;
    align-items: center;
    justify-content: center;

    &__info {
      margin-left: var(--spacing-base);
    }
  }

  .table {
    margin: auto;
    max-width: 1200px;
  }
}
