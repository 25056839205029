@import "../../styles/breakpoints.scss";

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--spacing-base);
  height: 150px;
  color: var(--gray-darker);
  background: #f9a620;
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.2);

  @include desktop {
    height: 200px;
  }

  &__text {
    font-weight: 500;
    font-size: var(--font-large);
    max-width: 60%;
  }

  &__icon {
    display: block;
    font-size: var(--font-large);
  }
}
