@import "../../styles/breakpoints.scss";

.card {
  margin: var(--spacing-huge) auto;

  @include tablet-portrait {
    width: 80%;
  }

  @include tablet-landscape {
    width: 70%;
  }

  @include desktop {
    width: 60%;
    max-width: 600px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-medium) 0;

    img {
      width: 100px;
      margin-bottom: var(--spacing-large);
    }

    &__input {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 90%;

      @include tablet-portrait {
        width: 70%;
      }

      @include desktop {
        width: 60%;
        max-width: 400px;
      }

      a {
        text-decoration: none;
        color: var(--brand-blue);
        font-size: var(--font-medium);
        margin: auto;
        margin-top: var(--spacing-base);

        &:hover {
          color: var(--brand-blue-light);
          transition: 300ms ease;
        }
      }
    }

    &__button {
      margin-top: var(--spacing-large);
      text-align: center;
      button {
        width: 200px;
      }
    }
  }
}
