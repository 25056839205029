@import "../../../styles/breakpoints.scss";

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;

  @include desktop {
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1000px;
    justify-content: center;
    align-items: flex-start;
    margin: var(--spacing-huge) auto;
  }

  .empty {
    margin-top: var(--spacing-huge);

    span {
      font-size: var(--font-medium);
    }
  }

  .card {
    width: 80%;
    max-width: 300px;
    margin: var(--spacing-base) 0;

    @include tablet-portrait {
      margin: var(--spacing-base);
    }

    &__content {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: var(--gray-dark);
        font-size: var(--font-medium);
      }
    }
  }
}

.buttons {
  margin: var(--spacing-huge) 0;
  display: flex;
  justify-content: space-around;

  @include tablet-portrait {
    justify-content: center;

    button {
      &:first-of-type {
        margin-right: var(--spacing-huge);
      }
    }
  }
}
