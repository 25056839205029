@import "../../styles/breakpoints.scss";

.container {
  
  @include desktop {
    margin-left: var(--sidebar-width);
  }
  
  background: var(--gray-lightest);

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 5%;
    color: var(--gray-darker);

    h2 {
      width: 60%;
      font-weight: 600;

      @include tablet-portrait {
        width: auto;
      }
    }

    @include tablet-portrait {
      justify-content: center;
    }
  }

  .container2 {
    padding: var(--spacing-low) var(--spacing-low);

    @include tablet-portrait {
      padding: var(--spacing-huge);
    }

    @include tablet-landscape {
      padding: var(--spacing-huge);
    }

    @include desktop {
      padding: var(--spacing-huge);
    }
  }

  .description {
    text-align: justify;
    padding: 0 8%;
    color: var(--gray-darker);

    @include tablet-portrait {
      padding: 0 15%;
    }

    p {
      margin: var(--spacing-large) 0;
      font-size: var(--font-medium);
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        margin: var(--spacing-base) 0;
      }

      @include tablet-portrait {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: var(--spacing-huge) auto;

        .button {
          &:first-of-type {
            margin-right: var(--spacing-huge);
          }
        }
      }
    }
  }
}