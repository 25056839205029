@import "../../styles/breakpoints.scss";
.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-base);
  margin: var(--spacing-huge) 0;

  @include tablet-landscape {
    flex-direction: row;
    justify-content: space-evenly;
    margin: var(--spacing-very-huge) 0;
  }

  a {
    width: 100%;
    max-width: 400px;

    .card {
      margin: var(--spacing-large) var(--spacing-base);

      .item {
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--gray-dark);

        i {
          font-size: var(--font-very-large);
          margin-bottom: var(--spacing-base);
        }
      }
    }
  }
}

.contact {
  background: var(--gray-darker);
  padding: var(--spacing-huge) var(--spacing-large);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  .form {
    margin: 0;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet-landscape {
      width: 40%;
    }

    .inputs {
      width: 100%;
      color: black;

      input {
        margin: var(--spacing-small) 0;
      }
    }

    button {
      margin-top: var(--spacing-large);
    }
  }
}
