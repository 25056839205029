@mixin smartphone {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: 600px) and (max-width: 899px) {
    @content;
  }
}
@mixin tablet-landscape {
  @media (min-width: 900px) and (max-width: 1199px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
