@import "../../../../styles/breakpoints.scss";

.whatisContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  color: var(--gray-darker);

  @include desktop {
    padding: 0 10em;
  }

  @include smartphone {
    padding: 35px 20px 5px 20px;
  }

  .whatisTitle {
    display: flex;
    align-items: center;

    h2 {
      font-size: var(--font-large);
      font-weight: 500;
      margin-right: var(--spacing-base);
      padding-bottom: 25px;
    }

    img {
      width: 3em;
      padding-bottom: 30px;
    }
  }

  .whatisDescription {
    p {
      font-size: var(--font-medium);
    }
  }
}
