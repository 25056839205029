@import "../../styles/breakpoints.scss";

.recruitment {
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttons {
    display: flex;
    margin: var(--spacing-large) 0;

    button {
      margin: 0 var(--spacing-small);
    }
  }

  .processes {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet-portrait {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 800px;
    }

    .card {
      margin: var(--spacing-medium) 0;

      @include tablet-portrait {
        margin: var(--spacing-medium);
      }
    }
  }
}
