@import "../../../../styles/breakpoints.scss";

.whatdoContainer {
  text-align: center;
  color: var(--gray-darker);

  .line {
    height: 1.5px;
    margin: 0 5em;
    background-color: var(--gray-light);

    @include desktop {
      margin: 2em 15em;
    }
  }

  @include desktop {
    padding: 0 6em;
  }

  .whatdoTitle {
    h2 {
      font-size: var(--font-large);
      font-weight: 500;
      @include smartphone {
        margin-top: 40px;
      }
    }
  }

  .whatdoDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include desktop {
      flex-direction: row;
    }

    .activitiesContainer {
      @include desktop {
        width: 50%;
      }

      p {
        font-size: var(--font-medium);
      }

      .cardsContainer {
        margin: var(--spacing-medium) 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cardContainer {
          width: 60%;
          margin: var(--spacing-base) 0;

          @include tablet-portrait {
            width: 300px;
          }

          .cardContentContainer {
            display: flex;
            justify-content: center;

            span {
              font-size: var(--font-normal);
              font-weight: 600;
              margin-right: var(--spacing-medium);

              @include tablet-portrait {
                font-size: var(--font-medium);
              }
            }
          }
        }
      }

      a {
        text-decoration: none;
        color: var(--brand-blue);
        font-size: var(--font-medium);
        font-weight: 600;

        &:hover {
          color: var(--brand-blue-light);
          transition: 300ms ease;
        }
      }
    }
  }
}
