@import "../../styles/breakpoints.scss";

.title {
  text-align: center;
  color: var(--gray-darker);
  h3 {
    font-size: var(--font-big);
  }
}

.description {
  text-align: center;
  margin-bottom: var(--spacing-huge);
}

.button {
  display: flex;
  justify-content: center;
}
