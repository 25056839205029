@import "../../../styles/breakpoints.scss";

.description {
  text-align: center;
  margin-bottom: var(--spacing-huge);
  color: var(--gray-dark);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__email {
    width: 90%;
    max-width: 400px;
    margin-bottom: var(--spacing-large);
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    button {
      &:last-of-type {
        margin-left: var(--spacing-huge);
        width: 140px;
      }
    }
  }
}
