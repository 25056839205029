@import "../../styles/breakpoints.scss";

.card {
  margin: var(--spacing-huge) auto;
  color: var(--gray-dark);
  text-align: center;

  @include tablet-portrait {
    width: 70%;
  }

  @include tablet-landscape {
    width: 50%;
  }

  @include desktop {
    width: 60%;
    max-width: 600px;
  }

  .form {
    padding: var(--spacing-medium) 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      h3 {
        font-size: var(--font-big);
      }
    }

    &__description {
      margin-bottom: var(--spacing-huge);
    }

    &__inputs {
      width: 80%;
      margin: auto;
      margin-bottom: var(--spacing-huge);

      @include tablet-landscape {
        width: 60%;
      }
    }

    &__button {
      text-align: center;
      button {
        width: 200px;
      }
    }
  }
}
