@import "../../styles/breakpoints.scss";

.button {
  padding: var(--spacing-base) var(--spacing-large);
  border-radius: var(--medium-radius);
  display: flex;
  justify-content: center;
  max-height: 50px;

  font-weight: 600;
  font-size: var(--font-normal);

  background-color: var(--gray-lighter);
  color: var(--gray-darker);
  box-shadow: var(--long-drop-shadow);
  transition: 300ms ease;

  &:hover {
    background-color: var(--gray-lightest);
  }

  .loader {
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-right: 3px solid rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
    border-left: 3px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loader 0.6s infinite linear;
    animation: loader 0.6s infinite linear;
    border-radius: 50%;
    height: 16px;
    width: 16px;

    &::after {
      border-radius: 50%;
      height: 16px;
      width: 16px;
    }

    @-webkit-keyframes loader {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes loader {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}

.primary {
  background-color: var(--brand-blue);
  color: #fff;

  &:hover {
    background-color: var(--brand-blue-light);
  }
}

.secondary {
  background-color: var(--brand-yellow);

  &:hover {
    background-color: var(--brand-yellow-light);
  }
}

.success {
  background-color: var(--color-success);
  color: #fff;

  &:hover {
    background-color: var(--color-success-light);
  }
}

.danger {
  background-color: var(--color-danger);
  color: #fff;

  &:hover {
    background-color: var(--color-danger-light);
  }
}
