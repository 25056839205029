@import "../../styles/breakpoints.scss";

.container {
  padding: 0 var(--spacing-medium);
  color: var(--gray-dark);
  max-width: 1000px;
  margin: auto;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .authForm {
      width: 100%;
      margin-bottom: var(--spacing-huge);
      display: flex;
      flex-direction: column;
      align-items: center;

      @include desktop {
        align-items: flex-start;
      }
    }

    .section {
      width: 100%;
      max-width: 400px;
      min-height: 300px;
      margin: var(--spacing-huge) 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @include desktop {
        margin: var(--spacing-huge) 0;
      }

      span {
        font-weight: 600;
      }

      &__birthday {
        height: 35px;
        margin: var(--spacing-medium) 0;
        display: flex;
        align-items: center;
      }

      &__dropdown {
        width: 240px;
        margin: var(--spacing-base) 0;
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &__buttons {
        display: flex;
        justify-content: flex-start;
        margin-top: var(--spacing-huge);

        @include desktop {
          justify-content: flex-start;
        }

        button {
          margin-right: 0;

          &:first-of-type {
            margin-right: var(--spacing-huge);
          }
        }
      }

      &__button {
        display: flex;
        justify-content: flex-start;
        margin-top: var(--spacing-large);
      }
    }
  }
}
