@import "../../../styles/breakpoints.scss";

.container {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .steps {
    min-height: 230px;
  }

  .stepsAction {
    margin: var(--spacing-medium) 0;
  }
}
