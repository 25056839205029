.loader {
  border-top: 3px solid var(--gray-light);
  border-right: 3px solid var(--gray-light);
  border-bottom: 3px solid var(--gray-light);
  border-left: 3px solid var(--gray-darkest);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 0.6s infinite linear;
  animation: loader 0.6s infinite linear;
  border-radius: 50%;
  height: var(--spacing-large);
  width: var(--spacing-large);

  &::after {
    border-radius: 50%;
    height: var(--spacing-large);
    width: var(--spacing-large);
  }

  @-webkit-keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
