@import "../../styles/breakpoints.scss";

.container {
  margin: var(--spacing-huge) auto;

  @include tablet-portrait {
    width: 80%;
  }

  @include tablet-landscape {
    width: 70%;
  }

  @include desktop {
    width: 60%;
    max-width: 600px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-medium) 0;
    color: var(--gray-dark);

    &__title {
      h3 {
        font-size: var(--font-big);
      }
    }

    &__description {
      text-align: center;
      padding: 0 var(--spacing-small);
    }

    &__input {
      margin-bottom: var(--spacing-base);
      width: 100%;

      @include tablet-portrait {
        width: 80%;
      }

      @include tablet-landscape {
        width: 60%;
      }

      @include desktop {
        width: 60%;
        max-width: 600px;
      }
    }

    &__button {
      text-align: center;

      button {
        width: 200px;
      }
    }
  }
}
