@import "../../styles/breakpoints.scss";

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet-portrait {
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1000px;
    justify-content: center;
    margin: var(--spacing-huge) auto;
  }

  .card {
    margin: var(--spacing-base) 0;

    @include tablet-portrait {
      margin: var(--spacing-base);
    }

    .item {
      width: 220px;
      height: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--gray-dark);

      &__icon {
        font-size: var(--font-very-large);
        margin-bottom: var(--spacing-small);
      }

      &__text {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}
