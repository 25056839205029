.select {
  display: block;
  padding: 0 var(--spacing-small);
  background: var(--gray-lighter);
  border-radius: 4px;
  border: 1px solid var(--gray-light);
  width: 100%;
  height: 48px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: var(--gray-dark);
  background-image: url("../../assets/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  .option {
    height: 48px;
  }
}
