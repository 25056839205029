@import "../../../styles/breakpoints.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include desktop {
    flex-direction: row;
    max-width: 1000px;
    margin: 0 auto;
  }

  .data {
    color: var(--gray-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: var(--spacing-large);

      &__label {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: var(--spacing-small);
      }

      span {
        font-size: var(--font-medium);
      }
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  margin: var(--spacing-large) 0;

  button {
    width: 140px;
  }
}
