@import "../../../styles/breakpoints.scss";

.container {
  padding: 0 var(--spacing-medium);
  color: var(--gray-dark);
  max-width: 1000px;
  margin: auto;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .section {
      width: 100%;
      max-width: 400px;
      min-height: 300px;
      margin: var(--spacing-huge) 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include desktop {
        margin: var(--spacing-huge) 0;
      }

      &__label {
        font-weight: 600;
      }

      &__birthday {
        height: 35px;
        margin: var(--spacing-medium) 0;
        display: flex;
        align-items: center;
      }

      &__dropdown {
        width: 240px;
        margin: var(--spacing-base) 0;
      }

      &__button {
        display: flex;
        justify-content: center;
        margin: var(--spacing-large) 0;

        @include desktop {
          justify-content: flex-start;
        }

        button {
          width: 160px;
        }
      }
    }
  }
}
