@import "~antd/dist/antd.css";

@media (max-width: 599px) {
  .title {
    margin-top: 40px;
  }
}

.testimonial {
  color: var(--gray-darker);
}

.overview {
  color: var(--gray-darker);
}