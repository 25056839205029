@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&display=swap");

:root {
  --url-logo: url("../assets/logos/logo.svg");

  /* 'margin', 'padding', 'top', 'right', ... */
  --spacing-tiny: 4px;
  --spacing-small: 8px;
  --spacing-base: 16px;
  --spacing-medium: 24px;
  --spacing-large: 32px;
  --spacing-huge: 48px;
  --spacing-very-huge: 96px;

  /* SideBar */
  --sidebar-width: 80px;
  --sidebar-height: 60px;
  --sidebar-item-height: 50px;
  --sidebar-item-height-desktop: 70px;
  --footer-height: 135px;
  --footer-height-desktop: 150px;

  /* 'font-size' */
  --font-small: 12px;
  --font-normal: 14px;
  --font-medium: 18px;
  --font-big: 24px;
  --font-large: 28px;
  --font-very-large: 34px;
  --font-huge: 42px;
  --font-very-huge: 54px;

  /* 'border-radius' */
  --small-radius: 2px;
  --medium-radius: 4px;
  --large-radius: 8px;
  --no-radius: 0;
  --full-radius: 50%;

  /* 'box-shadow' */
  --short-drop-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  --medium-drop-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  --long-drop-shadow: 0 8px 8px rgba(0, 0, 0, 0.16);
  --sidebar-shadow-mobile: 0 -5px 8px rgba(0, 0, 0, 0.16);
  --sidebar-shadow-desktop: 5px 0px 8px rgba(0, 0, 0, 0.16);

  /* z-index */
  --sidebar-z-index: 1000;

  /* Brand  Colors */
  --brand-yellow: #f2c94c;
  --brand-yellow-light: #eecc66;
  --brand-yellow-lighter: #ecdba6;
  --brand-blue-darker: #173b5f;
  --brand-blue-dark: #20568b;
  --brand-blue: #1089ff;
  --brand-blue-light: #45a2ff;
  --brand-blue-lighter: #89c1f8;
  --brand-blue-lightest: #e1eefb;

  /* State Colors */
  --color-info: var(--gray-light);
  --color-success: #1cb36a;
  --color-success-light: #34c981;
  --color-alert: var(--brand-yellow);
  --color-warning: #ff9100;
  --color-danger: #ec4949;
  --color-danger-light: #df7171;
  --color-purple: #970b97;
  --color-purple-light: #b83db8;

  /* Neutral Colors */
  --gray-lightest: #fafafa;
  --gray-lighter: #eceff1;
  --gray-light: #b0bec5;
  --gray: #78909c;
  --gray-dark: #37474f;
  --gray-darker: #263238;
  --gray-darkest: #222b2f;
}

[data-theme="light"] {
  --primary-color: var(--gray-dark);
  --secondary-color: var(--gray);
  --tertiary-color: var(--gray);
  --tag-color: var(--gray-dark);

  --bg-color: var(--gray-lighter);

  --bg-container-color: white;
  --border-container: 1px solid var(--gray-lighter);

  --bg-loading: var(--gray-light);
}

[data-theme="dark"] {
  --primary-color: white;
  --secondary-color: white;
  --tertiary-color: var(--gray-light);

  --bg-color: var(--gray-darkest);
  --bg-container-color: var(--gray-dark);
  --border-container: 1px solid var(--gray);

  --bg-loading: var(--gray-darker);
}

/* General Styles */

body {
  background-color: var(--bg-color);
  margin: 0;
  font-family: "Montserrat", "Roboto", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Montserrat", "Roboto", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline-color: var(--brand-blue);
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  box-sizing: border-box;
  padding: 0;
  border: none;
  background: none;
}

input:focus,
textarea:focus {
  outline-color: var(--brand-blue);
  outline: none;
  box-shadow: 0 0 3px var(--brand-blue);
}

.loader {
  display: flex;
  justify-content: center;
  padding: var(--spacing-large) 0;
}