@import "../../styles/breakpoints.scss";

.container {
  padding: var(--spacing-low) var(--spacing-low);

  @include tablet-portrait {
    padding: var(--spacing-huge);
  }

  @include tablet-landscape {
    padding: var(--spacing-huge);
  }

  @include desktop {
    padding: var(--spacing-huge);
  }
}
.container:hover {
  background-color: darkgoldenrod;
}
