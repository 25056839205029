@import "../../../../styles/breakpoints.scss";

.aboutContainer {
  background-color: #f9a620;
  text-align: center;

  .aboutTitle {
    h2 {
      font-size: var(--font-large);
      font-weight: 600;
    }

    @include desktop {
      margin-bottom: var(--spacing-huge);
    }
    @include smartphone {
      margin-top: 40px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .container {
      padding: var(--spacing-low) var(--spacing-low);
    
      @include tablet-portrait {
        padding: var(--spacing-huge);
      }
    
      @include tablet-landscape {
        padding: var(--spacing-huge);
      }
    
      @include desktop {
        padding: var(--spacing-huge);
      }
    
  }

  .numbersContainer {
    display: flex;
    flex-direction: column;

    span {
      font-size: var(--font-big);
      margin: var(--spacing-small) 0;
    }

    @include desktop {
      flex-direction: row;
      justify-content: center;

      span {
        font-size: var(--font-big);
        margin: 0 var(--spacing-large);
      }
    }
  }

  .partnersContainer {
    margin-top: var(--spacing-huge);
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 30%;
      margin: var(--spacing-small);
    }

    @include tablet-portrait {
      flex-direction: row;
      justify-content: center;

      img {
        width: 20%;
        margin: var(--spacing-large);
      }
    }

    @include tablet-landscape {
      img {
        width: 14%;
      }
    }

    @include desktop {
      img {
        width: 8%;
      }
    }
  }
}
