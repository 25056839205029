@import "../../styles/breakpoints.scss";

.overlay {
  width: 100%;
  height: 100%;
  background: var(--gray-darker);
  opacity: 0.5;
  position: absolute;
}

.modal {
  position: absolute;
  top: 30%;
  padding: var(--spacing-base);
  margin: var(--spacing-small);
  display: flex;
  flex-direction: column;
  background: var(--gray-lighter);
  border-radius: 4px;
  box-shadow: var(--medium-drop-shadow);

  @include tablet-portrait {
    margin: 0;
  }

  .title {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    &__icon {
      color: var(--gray-dark);
      padding: var(--spacing-small) var(--spacing-base);
      border-radius: 4px;
      cursor: pointer;
      transition: ease 300ms;

      &:hover {
        background: var(--gray-lightest);
      }
    }

    &__text {
      text-align: center;
      color: var(--gray-dark);
      width: 90%;
    }
  }
}
